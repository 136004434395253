<!-- src/views/assemblies/AssembliesModal.vue -->
<template>
  <v-dialog v-model="isModalOpen" max-width="1100px" data-cy="edit-modal" persistent>
    <v-card>
      <v-card-title class="modal-title" data-cy="modal-title">
        <span class="text-h5">{{ title }}</span>
        <v-icon small @click="close" data-cy="close-icon" style="right: 10px; top: 10px; position: absolute">mdi-close</v-icon>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <h4>Basic data</h4>
        <v-form ref="form" class="scrollable-modal" data-cy="form">
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field label="Name" v-model="currentItem.name" variant="underlined" data-cy="input-name"></v-text-field>
            </v-col>

            <v-col cols="12" md="8">
              <v-text-field label="Description" v-model="currentItem.description" variant="underlined" data-cy="input-description"></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="3">
              <v-select
                label="Assembly Type"
                :items="typeOptions"
                v-model="selectedType"
                variant="underlined"
                item-title="label"
                item-value="value"
                @update:modelValue="handleTypeChange"
              ></v-select>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field
                label="Code"
                v-if="currentItem.type == 'blueprint' || currentItem.type == 'values'"
                v-model="currentItem.code"
                variant="underlined"
                data-cy="input-code"
              ></v-text-field>
              <v-select
                label="Assembly Base Product"
                :items="assemblyBaseProductOptions"
                v-if="currentItem.type == 'assembly'"
                v-model="assemblyBaseProduct"
                variant="underlined"
                item-title="label"
                item-value="value"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" v-if="currentItem.type == 'blueprint'">
              <v-row class="d-flex align-center">
                <v-col>
                  <v-text-field label="Base PN" v-model="currentItem.baseProduct" variant="underlined" data-cy="input-base-product" :loading="loadingGenerateData"></v-text-field>
                </v-col>

                <v-col class="shrink">
                  <v-btn class="btn-orange" :disabled="!currentItem.baseProduct" @click="handleGenerateClick" data-cy="generate-button">Generate</v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6" v-if="currentItem.type == 'assembly'">
              <v-row class="d-flex align-center">
                <v-col>
                  <v-autocomplete
                    label="Root Module"
                    :items="availableComponents"
                    v-if="assemblyBaseProduct == 'component'"
                    v-model="rootModule"
                    variant="underlined"
                    item-title="name"
                    item-value="id"
                    return-object
                  ></v-autocomplete>
                  <v-autocomplete
                    label="Root Material"
                    :items="availableMaterials"
                    v-if="assemblyBaseProduct == 'material'"
                    v-model="rootModule"
                    variant="underlined"
                    item-title="name"
                    item-value="id"
                    return-object
                  ></v-autocomplete>
                </v-col>

                <v-col class="shrink">
                  <v-btn class="btn-orange" :disabled="!rootModule" @click="handleGenerateClick" data-cy="generate-button">Generate</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-text v-if="(currentItem.type == 'blueprint' || currentItem.type == 'assembly') && treeItemsGenerated.length">
        <div class="wrapper">
          <div class="wrapper-header"><h4>Structure</h4></div>
          <div class="wrapper-content">
            <v-row>
              <!-- <pre> {{ treeItemsGenerated }}</pre> -->
              <!-- TREE -->

              <v-col cols="12" md="6">
                <div v-if="loadingStructureData" class="loading-spinner" style="display: flex; justify-content: center; align-items: center; height: 100%">
                  <v-progress-circular indeterminate color="orange"></v-progress-circular>
                </div>
                <div v-else class="structure-container">
                  <pre></pre>
                  <v-treeview
                    :items="treeItemsGenerated"
                    item-value="id"
                    item-text="title"
                    selected-color="red"
                    v-model:selected="selectedItem"
                    activatable
                    return-object
                    open-on-click
                  >
                    <template v-slot:title="{ item }">
                      <div @click="handleTitleClick(item)">
                        <span class="custom-title">{{ item.name }}</span>
                      </div>
                    </template>

                    <template v-slot:prepend="{ item }">
                      <span @click="handleTitleClick(item)" class="custom-prepend"></span>
                    </template>
                  </v-treeview>
                </div>
              </v-col>
              <!-- end TREE -->

              <!-- DETAILS  -->
              <v-col>
                <div class="details-container">
                  <div class="module-info" style="display: flex; align-items: center; justify-content: space-between">
                    <h3 style="margin: 0">
                      Module type:
                      <br />
                      <span style="font-weight: 500">{{ itemDetails?.name }}</span>
                    </h3>
                    <v-btn style="margin-right: 28px" class="btn-orange" @click="updateStructure" data-cy="formula-btn">Update</v-btn>
                    <v-btn v-if="itemDetails?.name.includes('Branch')" class="btn-orange" @click="branchModal(itemDetails)" data-cy="re-generate-btn">Add Furcation</v-btn>
                  </div>
                  <br />

                  <div class="text-details" data-cy="details-content">
                    <div>
                      <!-- CONTAINS -->
                      <v-row>
                        <v-col cols="12" md="12" style="padding-bottom: 0px">
                          <v-card-text class="section-item">
                            <v-toolbar flat class="section-title">
                              <v-toolbar-title>Contains</v-toolbar-title>
                              <v-col class="d-flex align-center" cols="auto">
                                <v-autocomplete
                                  label="Select Logic Module"
                                  :items="availableComponents"
                                  v-model="selectedComponentType"
                                  variant="underlined"
                                  data-cy="select-component-type"
                                  clearable
                                  style="width: 200px"
                                  item-title="name"
                                  return-object
                                  :menu-props="{ maxHeight: '250px' }"
                                ></v-autocomplete>
                                <v-btn class="btn-orange ml-2" @click="addComponentToContainsList" data-cy="add-contains-btn">
                                  <v-icon left>mdi-plus</v-icon>
                                  Add
                                </v-btn>
                              </v-col>
                            </v-toolbar>

                            <v-table density="compact">
                              <template v-slot:default>
                                <thead>
                                  <tr>
                                    <th style="min-width: 130px">Name</th>
                                    <th>Quantity</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(component, index) in containsList" :key="index">
                                    <td>{{ component.name }}</td>
                                    <td><v-text-field v-model="component.quantity" variant="underlined" data-cy="quantity-input"></v-text-field></td>
                                    <td>
                                      <span :class="{ 'disabled-btn': !component.isUserAdded }" @click="removeComponentFromContainsList(index)">
                                        <i class="fa-duotone fa-solid fa-trash"></i>
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-table>
                          </v-card-text>
                        </v-col>
                      </v-row>
                      <!-- end CONTAINS -->

                      <!-- ATTRIBUTE MAIN-->
                      <v-row>
                        <v-col cols="12" md="12" style="padding-bottom: 0px">
                          <v-card-text class="section-item">
                            <v-toolbar flat class="section-title">
                              <v-toolbar-title>Attributes</v-toolbar-title>
                              <v-col class="d-flex align-center" cols="auto">
                                <v-autocomplete
                                  label="Select Attribute"
                                  :items="availableAttributesTrimmed"
                                  v-model="selectedAttribute"
                                  variant="underlined"
                                  data-cy="select-attribute"
                                  clearable
                                  style="width: 200px"
                                  item-title="name"
                                  return-object
                                  :menu-props="{ maxHeight: '250px' }"
                                ></v-autocomplete>
                                <v-btn class="btn-orange ml-2" @click="addAttributeWithoutValue" data-cy="add-attributes-btn">
                                  <v-icon left>mdi-plus</v-icon>
                                  Add
                                </v-btn>
                              </v-col>
                            </v-toolbar>
                            <v-table density="compact">
                              <template v-slot:default>
                                <thead>
                                  <tr>
                                    <th style="min-width: 130px">Name</th>
                                    <th>Value</th>
                                    <th>UoM</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(attr, index) in itemDetails?.attributeValues" :key="index">
                                    <td>{{ attr.description }}</td>

                                    <td>
                                      <div>
                                        <template v-if="findAvailableAttributeById(attr.attributeId)?.values?.length">
                                          <v-select
                                            v-model="attr.value"
                                            :items="findAvailableAttributeById(attr.attributeId)?.values"
                                            variant="underlined"
                                            item-title="value"
                                            item-value="value"
                                            data-cy="enum-select"
                                            @update:modelValue="markAsModified(attr)"
                                          ></v-select>
                                        </template>

                                        <template v-else>
                                          <v-text-field
                                            v-model="attr.value"
                                            variant="underlined"
                                            data-cy="enum-description-input"
                                            @update:modelValue="markAsModified(attr)"
                                          ></v-text-field>
                                        </template>
                                      </div>
                                    </td>

                                    <td>{{ attr.uom }}</td>

                                    <td>
                                      <span :class="{ 'disabled-btn': !attr.isUserAdded }" @click="removeAttribute(index)">
                                        <i class="fa-duotone fa-solid fa-trash"></i>
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-table>
                          </v-card-text>
                        </v-col>
                      </v-row>
                      <!-- end ATTRIBUTE MAIN-->
                    </div>
                  </div>
                </div>
              </v-col>
              <!-- end DETAILS  -->
            </v-row>
          </div>
        </div>
      </v-card-text>

      <!-- ATTRIBUTES VALUES -->
      <v-card-text v-if="currentItem.type == 'values'">
        <div class="wrapper">
          <div class="wrapper-header"><h4>Attributes</h4></div>
          <!-- {{ availableAttributes }} -->
          <v-col class="d-flex align-center">
            <v-autocomplete
              label="Select Attribute"
              :items="availableAttributesTrimmed"
              v-model="selectedAttribute"
              variant="underlined"
              data-cy="select-attribute"
              clearable
              style="width: 200px"
              item-title="name"
              return-object
              :menu-props="{ maxHeight: '250px' }"
            ></v-autocomplete>

            <v-btn class="btn-orange ml-2" @click="addAttribute" data-cy="add-attributes-btn">
              <v-icon left>mdi-plus</v-icon>
              Add
            </v-btn>
          </v-col>

          <v-card-text>
            <v-table density="compact">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th style="min-width: 130px">Name</th>
                    <th>Value</th>
                    <th>UoM</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(attr, index) in itemAttributes?.attributeValues" :key="index">
                    <td>{{ attr.description }}</td>

                    <td>
                      <div>
                        <template v-if="findAvailableAttributeById(attr.attributeId)?.values?.length">
                          <v-select
                            v-model="attr.value"
                            :items="findAvailableAttributeById(attr.attributeId)?.values"
                            variant="underlined"
                            item-title="value"
                            item-value="value"
                            data-cy="enum-select"
                          ></v-select>
                        </template>

                        <template v-else>
                          <v-text-field v-model="attr.value" variant="underlined" data-cy="enum-description-input"></v-text-field>
                        </template>
                      </div>
                    </td>

                    <td>{{ attr.uom }}</td>

                    <td>
                      <span @click="removeAttribute(index)">
                        <i class="fa-duotone fa-solid fa-trash"></i>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-table>
          </v-card-text>
        </div>
      </v-card-text>
      <!-- end ATTRIBUTES VALUES -->

      <v-card-actions data-cy="modal-actions" class="btn-modal-bottom">
        <v-spacer></v-spacer>
        <v-btn class="btn-cancel" @click="close" data-cy="cancel-btn">Cancel</v-btn>
        <v-btn class="btn-save" :disabled="!isFormValid" @click="save" data-cy="save-btn">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <Dialogs :cancelDialog="cancelDialog" @closeCancelDialog="closeCancelDialog" @confirmCancel="confirmCancel" />

  <!-- Generate Confirmation Dialog -->
  <v-dialog v-model="generateDialog" max-width="400px" data-cy="generate-dialog">
    <v-card data-cy="generate-dialog-card">
      <v-card-title class="headline" data-cy="generate-dialog-title">Confirm Action</v-card-title>
      <v-card-text data-cy="generate-dialog-text">Update Data? All current data will be lost. Do you want to continue?</v-card-text>
      <v-card-actions data-cy="generate-dialog-actions" class="btn-modal-bottom">
        <v-spacer></v-spacer>
        <v-btn class="btn-cancel" @click="closeGenerateDialog" data-cy="generate-no-button">No</v-btn>
        <v-btn class="btn-delete" @click="confirmGenerate" data-cy="generate-yes-button">Yes</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="branchModalDialog" max-width="1100px">
    <v-card>
      <v-card-title class="headline">Insert Branches</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-slider
              v-model="bmd_branch_count"
              :min="1"
              :max="20"
              :step="1"
              :label="`Select branch count: ${Math.ceil(bmd_branch_count)}`"
              tick-size="10"
              data-cy="branch-count-slider"
            ></v-slider>
          </v-col>
        </v-row>
        <br />
        <hr />
        <br />
        <v-row>
          <v-col v-for="(connectorCount, index) in bmd_connectors_count" :key="index" cols="12" md="4">
            <v-text-field v-model="bmd_connectors_count[index]" :label="`Connectors Count Branch ${index + 1}`" variant="outlined" data-cy="connectors-count"></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="btn-modal-bottom">
        <v-spacer></v-spacer>
        <v-btn class="btn-cancel" @click="branchModalDialog = false">Cancel</v-btn>
        <v-btn class="btn-orange" @click="handleBranchModalSubmit">Insert</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import { VTreeview } from 'vuetify/labs/VTreeview';
  import { MaterialItem } from '@/types/components';
  import { AttributeItem, AssemblyItem, ComponentItem } from '@/types/assembly';
  import Dialogs from '@/components/Dialogs.vue';
  import assemblyService from '@/services/assemblyService';

  const BRANCH_TYPE_ID = '66f93b60530fba005e6f5c86';
  const BREAKOUT_TYPE_ID = '66cdda1e5f893788612b3c44';
  const TRANSITION_TYPE_ID = '66f9849f530fba005e70ac88';
  const CONNECTOR_COUNT_ATTR_ID = '66e03e0bd8c004068df51785';

  export default defineComponent({
    components: {
      VTreeview,
      Dialogs,
    },
    props: {
      title: {
        type: String,
        required: true,
      },
      currentItem: {
        type: Object as PropType<AssemblyItem>,
        required: true,
        default: () => ({
          id: '',
          type: null,
          name: '',
          description: '',
          code: null,
          components: [],
        }),
      },
      availableAttributes: {
        type: Array as PropType<AttributeItem[]>,
        required: true,
        default: () => [],
      },
      availableComponents: {
        type: Array as PropType<ComponentItem[]>,
        required: true,
        default: () => [],
      },
      availableMaterials: {
        type: Array as PropType<MaterialItem[]>,
        required: true,
        default: () => [],
      },
    },
    emits: ['close', 'save'],
    data() {
      return {
        isModalOpen: true,
        cancelDialog: false,
        generateDialog: false,
        branchModalDialog: false,
        branchModalData: {
          attributeValues: [] as { description: string; attributeId: string; value: string | number }[],
          children: [] as any[],
        },
        bmd_branch_count: 2,
        bmd_connectors_count: [2, 2],
        loadingGenerateData: false,
        loadingStructureData: false,
        newType: null,
        selectedType: this.currentItem.type,
        typeOptions: [
          { label: 'Blueprint', value: 'blueprint' },
          { label: 'Sub-Assembly', value: 'assembly' },
          { label: 'Code Values', value: 'values' },
        ] as { label: string; value: string }[],
        treeItemsGenerated: [] as any[],
        selectedItem: null as any,
        itemDetails: null as any,
        selectedAttribute: null as any,
        itemAttributes: { attributeValues: [] } as any,
        selectedComponentType: null as any,
        containsList: [] as any[],
        rootModule: null as any,
        assemblyBaseProduct: 'component',
        assemblyBaseProductOptions: [
          { label: 'Modules', value: 'component' },
          { label: 'Raw Materials', value: 'material' },
        ] as { label: string; value: string }[],
      };
    },
    mounted() {
      if (this.title === 'Edit Assembly') {
        if (this.currentItem.type !== 'values') {
          this.generateTreeItems();
          const id = this.currentItem.component?.componentTypeId;
          const component = this.availableComponents.find((component) => component.id === id);
          this.rootModule = component?.name;
        } else if (this.currentItem.type === 'values') {
          this.generateAttributeItems();
        }
      }
    },
    watch: {
      bmd_branch_count(newCount) {
        this.reCalculateBranchConnectorsCount(newCount);
      },
    },
    computed: {
      availableAttributesTrimmed() {
        return this.availableAttributes.map((component) => ({
          ...component,
          name: component.name.length > 25 ? component.name.substring(0, 25) + '...' : component.name,
        }));
      },
      isFormValid() {
        if (!this.currentItem.name || !this.selectedType) {
          return false;
        }
        if (this.currentItem.type === 'assembly' && !this.rootModule) {
          return false;
        }
        if (this.currentItem.type === 'blueprint' && (!this.currentItem.code || !this.currentItem.baseProduct)) {
          return false;
        }
        if (this.currentItem.type === 'values' && !this.currentItem.code) {
          return false;
        }
        return true;
      },
    },
    methods: {
      handleClose() {
        if (this.hasChanges()) {
          this.cancelDialog = true;
        } else {
          this.closeModal();
        }
      },
      handleGenerateClick() {
        if (this.treeItemsGenerated.length > 0) {
          this.generateDialog = true;
        } else {
          if (this.currentItem.type === 'blueprint') {
            this.generateBasePN(this.currentItem.baseProduct);
          } else if (this.currentItem.type === 'assembly') {
            this.generateStructure(this.rootModule.id);
          }
        }
      },
      handleTypeChange(newTypeValue: any) {
        this.newType = newTypeValue;
        this.confirmTypeChangeWithDialog();
      },
      handleTitleClick(item: any) {
        this.itemDetails = item;
        this.populateContainsList(item.componentTypeId);
      },
      branchModal(item: any) {
        this.branchModalDialog = true;
        this.branchModalData = item;
        this.reCalculateBranchConnectorsCount(this.bmd_branch_count);
      },
      reCalculateBranchConnectorsCount(branchCount: number) {
        const connectorsCount = this.branchModalData.attributeValues.find(({ attributeId }) => attributeId === CONNECTOR_COUNT_ATTR_ID)?.value;

        if (connectorsCount) {
          const amount = Math.floor(Number(connectorsCount) / branchCount);
          this.bmd_connectors_count = Array(branchCount).fill(amount);
        } else {
          this.bmd_connectors_count = [];
        }
      },
      handleBranchModalSubmit() {
        if (this.bmd_branch_count < 1) {
          alert('Branch count must be at least 1.');
          return;
        }

        this.branchModalData.children = [
          ...this.branchModalData.children.filter(({ name }) => name.toLowerCase().includes('label')),
          {
            componentTypeId: BREAKOUT_TYPE_ID,
            name: `Branch Breakout`,
            children: [
              {
                componentTypeId: TRANSITION_TYPE_ID,
                name: 'Transition',
                children: [],
              },
              ...this.bmd_connectors_count.map((value, i) => ({
                componentTypeId: BRANCH_TYPE_ID,
                attributeValues: [
                  {
                    value,
                    attributeId: CONNECTOR_COUNT_ATTR_ID,
                    description: 'Connectors Count',
                  },
                ],
                name: `Branch ${i + 1}`,
                children: [],
              })),
            ],
          },
        ];

        this.branchModalDialog = false;
        this.updateStructure();
      },
      confirmTypeChangeWithDialog() {
        if ((this.currentItem.type !== undefined && this.treeItemsGenerated.length > 0) || this.itemAttributes.attributeValues.length > 0) {
          this.cancelDialog = true;
        } else {
          this.confirmCancel();
        }
      },
      confirmGenerate() {
        this.clearGenerationData();
        if (this.currentItem.type === 'blueprint') {
          this.generateBasePN(this.currentItem.baseProduct);
        } else if (this.currentItem.type === 'assembly') {
          this.generateStructure(this.rootModule.id);
        }
        this.closeGenerateDialog();
      },
      confirmCancel() {
        this.currentItem.type = this.newType;
        this.clearSectionData();
        this.treeItemsGenerated = [];
        this.cancelDialog = false;
      },
      closeCancelDialog() {
        this.selectedType = this.currentItem.type;
        this.newType = null;
        this.cancelDialog = false;
      },
      closeGenerateDialog() {
        this.generateDialog = false;
      },
      closeModal() {
        this.isModalOpen = false;
        this.$emit('close');
      },
      close() {
        this.handleClose();
      },
      clearSectionData() {
        this.itemDetails = null;
        this.selectedAttribute = null;
        this.itemAttributes = { attributeValues: [] };
        this.selectedComponentType = null;
        this.containsList = [];
        this.rootModule = null;
        this.currentItem.baseProduct = '';
        this.currentItem.code = '';
      },
      async generateBasePN(baseProduct?: string) {
        this.loadingGenerateData = true;
        this.treeItemsGenerated = [];
        this.itemDetails = null;

        if (baseProduct?.length) {
          try {
            const response = await assemblyService.generateAssembly(baseProduct);
            if (response) {
              const data = response.tree;
              this.treeItemsGenerated = [data];
            }
          } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || 'An unknown error occurred';
          }
        }
        this.loadingGenerateData = false;
      },

      async generateStructure(rootModuleId?: string) {
        this.loadingGenerateData = true;
        this.treeItemsGenerated = [];
        this.itemDetails = null;

        if (rootModuleId) {
          try {
            if (this.assemblyBaseProduct === 'component') {
              const response = await assemblyService.generateComponent(rootModuleId);

              if (response) {
                const data = response.tree;
                this.treeItemsGenerated = [data];
              }
            } else if (this.assemblyBaseProduct === 'material') {
              const response = await assemblyService.generateMaterial(rootModuleId);
              if (response) {
                const data = response.tree;
                this.treeItemsGenerated = [data];
              }
            }
          } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || 'An unknown error occurred';
            // Handle error appropriately
          }
        }
        this.loadingGenerateData = false;
      },
      generateTreeItems() {
        const loadedTree = this.currentItem.component;

        const findComponentById = (id: string) => {
          return this.availableComponents.find((component) => component.id === id);
        };

        const findAttributeById = (id: string) => {
          return this.availableAttributes.find((attribute) => attribute.id === id);
        };

        const generateTree = (tree: any) => {
          const component = findComponentById(tree.componentTypeId);
          const generateRandomId = () => Math.random().toString(36).substring(7);

          return {
            id: generateRandomId(),
            componentTypeId: tree.componentTypeId,
            name: component?.name || 'Unknown Component',
            children: tree.children ? tree.children.map((child: any) => generateTree(child)) : [],
            attributeValues: tree.attributeValues
              ? tree.attributeValues.map((attr: any) => {
                  const attribute = findAttributeById(attr.attributeId);
                  return {
                    id: generateRandomId(),
                    attributeId: attr.attributeId,
                    description: attribute?.name || 'Unknown Attribute',
                    value: attr.value,
                    isUserAdded: false,
                    isUserModified: false,
                  };
                })
              : [],
          };
        };

        this.treeItemsGenerated = [generateTree(loadedTree)];

        if (this.treeItemsGenerated.length > 0) {
          this.handleTitleClick(this.treeItemsGenerated[0]);
        }
      },
      generateAttributeItems() {
        const loadedAttributes = this.currentItem.component?.attributeValues || [];

        if (!loadedAttributes.length) {
          this.itemAttributes = { attributeValues: [] };
          return;
        }

        const findAttributeById = (id: string) => {
          return this.availableAttributes.find((attribute) => attribute.id === id);
        };

        this.itemAttributes = {
          attributeValues: loadedAttributes.map((attr: any) => {
            const attribute = findAttributeById(attr.attributeId);

            return {
              id: attr.attributeId,
              attributeId: attr.attributeId,
              description: attribute?.name || 'Unknown Attribute',
              uom: attribute?.unit || '',
              value: attr.value || '',
              isUserAdded: false,
              isUserModified: false,
            };
          }),
        };
      },
      populateContainsList(componentTypeId: string) {
        const component = this.availableComponents.find((component) => component.id === componentTypeId);
        if (!component) return;

        const contains = component.contains;
        this.containsList = contains.map((item) => {
          const comp = this.availableComponents.find((component) => component.id === item.componentTypeId);
          return {
            id: item.id,
            componentTypeId: item.componentTypeId,
            name: comp?.name || 'Unknown Component',
            quantity: item.quantity,
            isUserAdded: false,
            isUserModified: false,
          };
        });
      },

      prepareDataForSave() {
        interface ChildComponentData {
          componentTypeId: string;
          attributeValues: { attributeId: string; value: any }[];
          contains: { componentTypeId: string; quantity: number }[];
          children: ChildComponentData[];
        }

        const generateChildrenData = (children: any[]): ChildComponentData[] => {
          return children.map(
            (child: any): ChildComponentData => ({
              componentTypeId: child.componentTypeId,
              attributeValues:
                child.attributeValues
                  ?.filter(({ value }: { value: unknown }) => !!value)
                  .map((attr: any) => ({
                    attributeId: attr.attributeId,
                    value: attr.value,
                  })) || [],
              contains: this.containsList.map((contain) => ({
                componentTypeId: contain.componentTypeId,
                quantity: contain.quantity,
              })),
              children: generateChildrenData(child.children || []),
            })
          );
        };

        let dataToSave: any = {
          name: this.currentItem.name || '',
          description: this.currentItem.description || '',
          code: this.currentItem.code || '',
        };

        if (this.currentItem.type === 'blueprint') {
          const rootComponent = this.treeItemsGenerated.length > 0 ? this.treeItemsGenerated[0] : null;
          if (!rootComponent) {
            console.error('No root component found in treeItemsGenerated.');
            return null;
          }

          const component = {
            componentTypeId: rootComponent.componentTypeId || rootComponent.id_orig,
            attributeValues: this.itemAttributes.attributeValues.map((attr: any) => ({
              attributeId: attr.attributeId,
              value: attr.value,
            })),
            contains: this.containsList.map((contain) => ({
              componentTypeId: contain.componentTypeId,
              quantity: contain.quantity,
            })),
            children: generateChildrenData(rootComponent.children || []),
          };

          dataToSave = {
            ...dataToSave,
            type: this.currentItem.type || '',
            baseProduct: this.currentItem.baseProduct || '',
            component: component,
          };
        } else if (this.currentItem.type === 'assembly') {
          const rootComponent = this.treeItemsGenerated.length > 0 ? this.treeItemsGenerated[0] : null;
          if (!rootComponent) {
            console.error('No root component found in treeItemsGenerated.');
            return null;
          }

          const component = {
            componentTypeId: rootComponent.componentTypeId || rootComponent.id_orig,
            attributeValues: rootComponent.attributeValues.map((attr: any) => ({
              attributeId: attr.attributeId,
              value: attr.value,
            })),
            contains: this.containsList.map((contain) => ({
              componentTypeId: contain.componentTypeId,
              quantity: contain.quantity,
            })),
            children: generateChildrenData(rootComponent.children || []),
          };

          dataToSave = {
            ...dataToSave,
            type: this.currentItem.type || '',
            rootModule: this.rootModule || '',
            component: component,
          };
        } else if (this.currentItem.type === 'values') {
          const attributeValues = this.itemAttributes.attributeValues.map((attr: any) => ({
            attributeId: attr.attributeId,
            value: attr.value,
          }));

          dataToSave = {
            ...dataToSave,
            type: this.currentItem.type || '',
            component: {
              attributeValues: attributeValues,
              children: [],
            },
          };
        }

        return dataToSave;
      },

      save() {
        if (!this.isFormValid) {
          return;
        }
        const preparedData = this.prepareDataForSave();
        this.$emit('save', preparedData);
        this.closeModal();
      },
      async reGenerate() {
        this.loadingStructureData = true;
        const preparedData = this.prepareDataForSave();

        if (!preparedData) {
          return;
        }

        try {
          const response = await assemblyService.reGenerateAssembly(preparedData);

          if (response && response.data && response.data.component) {
            this.currentItem.component = response.data.component;
            this.generateTreeItems();
            this.loadingStructureData = false;
          } else {
            this.loadingStructureData = false;
            this.$log.showError(`Error during re-generate:: ${response}`);
          }
        } catch (error: any) {
          this.loadingStructureData = false;
          const errorMessage = error.response?.data?.message || error.message || 'An unknown error occurred';
          this.$log.showError(`Error during re-generate:: ${errorMessage}`);
        }
      },
      updateStructure() {
        const updateComponentChildren = (component: any) => {
          if (component.attributeValues?.some((attr: any) => attr.isUserModified)) {
            component.children = [];
          } else if (component.children && component.children.length > 0) {
            component.children.forEach((child: any) => updateComponentChildren(child));
          }
        };

        if (this.treeItemsGenerated.length > 0) {
          updateComponentChildren(this.treeItemsGenerated[0]);
        }

        this.reGenerate();
      },

      markAsModified(attr: any) {
        attr.isUserModified = true;
      },

      addComponentToContainsList() {
        if (!this.selectedComponentType) {
          return;
        }

        const isAlreadyAdded = this.containsList.some((component: any) => component.componentTypeId === this.selectedComponentType.id);

        if (isAlreadyAdded) {
          alert('Component already added.');
          return;
        }

        const componentToAdd = {
          id: Math.random().toString(36).substring(7),
          componentTypeId: this.selectedComponentType.id,
          name: this.selectedComponentType.name,
          isUserAdded: true,
          isUserModified: false,
        };

        this.containsList.push(componentToAdd);

        if (!this.itemDetails.children) {
          this.itemDetails.children = [];
        }

        const attributeValues = this.selectedComponentType.attributes.map((attr: any) => {
          const attribute = this.findAvailableAttributeById(attr.attributeId);
          return {
            id: Math.random().toString(36).substring(7),
            attributeId: attr.attributeId,
            description: attribute?.name || 'Unknown Attribute',
            uom: attribute?.unit || '',
            value: null,
            isUserAdded: false,
            isUserModified: false,
          };
        });

        this.itemDetails.children.push({
          id: componentToAdd.id,
          componentTypeId: this.selectedComponentType.id,
          name: this.selectedComponentType.name,
          children: [],
          attributeValues: attributeValues,
          availableAttributeValues: attributeValues,
        });

        this.selectedComponentType = null;
      },
      removeComponentFromContainsList(index: any) {
        this.containsList.splice(index, 1);
      },
      addAttribute() {
        if (!this.selectedAttribute) return;

        const attribute = {
          id: Math.random().toString(36).substring(7),
          attributeId: this.selectedAttribute.id,
          description: this.selectedAttribute.name,
          uom: this.selectedAttribute.unit,
          value: null,
        };
        this.selectedAttribute = null;

        this.itemAttributes.attributeValues.push(attribute);
      },
      addAttributeWithoutValue() {
        if (!this.selectedAttribute) return;

        const attribute = {
          id: Math.random().toString(36).substring(7),
          attributeId: this.selectedAttribute.id,
          description: this.selectedAttribute.name,
          uom: this.selectedAttribute.unit,
          value: null,
          isUserAdded: true,
          isUserModified: false,
        };
        this.selectedAttribute = null;

        this.itemDetails.attributeValues.push(attribute);
      },
      removeAttribute(index: number) {
        this.itemDetails?.attributeValues.splice(index, 1);
      },
      clearGenerationData() {
        this.containsList = [];
        this.selectedComponentType = null;
        this.itemAttributes = { attributeValues: [] };
        this.itemDetails = null;
        this.selectedAttribute = null;
      },
      hasChanges() {
        return JSON.stringify(this.currentItem) !== JSON.stringify(this.$props.currentItem);
      },
      findAvailableAttributeById(attributeId: string) {
        return this.availableAttributes.find((attr) => attr.id === attributeId);
      },
    },
  });
</script>

<style scoped>
  .modal-title {
    background-color: #3b516b;
    color: white;
  }
  div:deep(td) {
    border: none !important;
  }
  div:deep(.v-input__details) {
    padding-top: 0px !important;
    grid-area: auto !important;
  }
  .formula-btn {
    text-transform: none;
  }
  .copy-btn {
    cursor: pointer;
    margin-left: 5px;
  }
  .copy-btn:hover {
    color: orange;
  }

  .wrapper {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 0px;
    margin-top: 10px;
  }
  .wrapper-header {
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  .wrapper-content {
    padding: 10px;
  }
  .custom-title {
    cursor: pointer;
    font-weight: bold;
  }
  .fa-trash {
    cursor: pointer;
  }
  .fa-trash:hover {
    color: red;
  }

  .disabled-btn {
    cursor: not-allowed;
    color: #ccc;
    pointer-events: none;
  }
</style>
