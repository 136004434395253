<!-- src/views/materials/MaterialsModal.vue -->
<template>
  <v-dialog v-model="modal" max-width="1100px" data-cy="modal" persistent>
    <v-card data-cy="modal-card">
      <v-card-title class="modal-title" data-cy="modal-title">
        <span class="text-h5">{{ modalTitle }}</span>
        <v-icon small @click="closeModal" data-cy="close-icon" style="right: 10px; top: 10px; position: absolute">mdi-close</v-icon>
      </v-card-title>
      <v-card-text data-cy="modal-content">
        <h4>Basic data</h4>
        <v-form ref="form" class="scrollable-modal" data-cy="form">
          <v-row>
            <v-col cols="12" sm="12">
              <v-autocomplete
                label="Odoo Raw Material"
                :items="odooMaterialsOptions"
                v-model="selected.odooMaterial"
                variant="underlined"
                item-title="display_name"
                data-cy="select-odoo-material"
                clearable
                return-object
                :loading="loadingMaterials"
                :search-input.sync="searchOdooMaterials"
                @update:search="onSearchOdooMaterials"
                @update:modelValue="onOdooMaterialSelected"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4">
              <v-autocomplete
                label="Product Family Code"
                :items="productFamilyOptions"
                v-model="selected.productFamilyCode"
                variant="underlined"
                item-title="label"
                data-cy="select-product-family"
                clearable
                return-object
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4">
              <v-autocomplete
                label="Logic Module"
                :items="filteredComponents"
                v-model="selected.componentType"
                variant="underlined"
                item-title="name"
                data-cy="select-product-family"
                clearable
                return-object
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select :items="genUnitOptions" v-model="selected.uomId" return-object clearable data-cy="form-unit" variant="underlined">
                <template #item="data">
                  <v-list-subheader v-if="data.props && data.props.header" class="uom-select-header">
                    <b>{{ data.props.header }}</b>
                  </v-list-subheader>
                  <v-divider v-else-if="data.props && data.props.divider" />
                  <v-list-item v-else v-bind="data.props"></v-list-item>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-text>
        <v-data-table :headers="attributesHeaders" :items="selected.attributes" item-value="name" class="elevation-1 custom-table" data-cy="attributes-table">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Attributes</v-toolbar-title>
            </v-toolbar>
          </template>
          <template v-slot:item.value="{ item }">
            <div>
              <v-text-field
                v-if="!item.values || item.values.length === 0"
                v-model="(item as Attribute).value!.value"
                variant="underlined"
                data-cy="enum-description-input"
                :loading="loadingDataFromPM"
              ></v-text-field>

              <v-combobox
                v-else
                v-model="(item as Attribute).value!.value"
                :items="item.values ? item.values.slice().sort((a: any, b: any) => a.value.localeCompare(b.value)) : []"
                variant="underlined"
                item-title="value"
                item-value="value"
                data-cy="enum-select"
                clearable
                :loading="loadingDataFromPM"
              ></v-combobox>
            </div>
          </template>
          <template v-slot:item.uom="{ item }">
            {{ item.uom }}
          </template>
        </v-data-table>
      </v-card-text>




      <v-card-actions data-cy="modal-actions" class="btn-modal-bottom">
        <v-spacer></v-spacer>
        <v-btn class="btn-cancel" @click="closeModal" data-cy="cancel-button">Cancel</v-btn>
        <v-btn class="btn-save" @click="saveMaterial(modalTitle!)" data-cy="save-button">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import { OdooMaterial, ProductFamily, Attribute, uom } from '@/types/materials';

  export default defineComponent({
    name: 'MaterialsModal',
    props: {
      modal: Boolean,
      modalTitle: String,
      odooMaterialsOptions: Array as PropType<OdooMaterial[]>,
      selected: {
        type: Object as PropType<{
          odooMaterial: OdooMaterial | null;
          productFamilyCode: ProductFamily | null;
          componentType: any;
          attributes: Attribute[];
          uomId: uom | null;
        }>,
        default: () => ({
          odooMaterial: null,
          productFamilyCode: null,
          componentType: null,
          attributes: [],
          uomId: null,
        }),
      },
      productFamilyOptions: Array as PropType<ProductFamily[]>,
      filteredComponents: Array,
      genUnitOptions: Array,
      loadingMaterials: Boolean,
      searchOdooMaterials: String,
      loadingDataFromPM: Boolean,
    },
    data() {
      return {
        attributesHeaders: [
          { title: 'Name', value: 'name' },
          { title: 'Description', value: 'description' },
          { title: 'Value', value: 'value' },
          { title: 'Uom', value: 'uom' },
        ],
      };
    },
    methods: {
      closeModal() {
        this.$emit('close');
      },
      saveMaterial(modalTitle: string) {
        this.$emit('save', modalTitle);
      },
      onSearchOdooMaterials(value: string) {
        this.$emit('search-odoo-materials', value);
      },
      onOdooMaterialSelected(value: OdooMaterial) {
        this.$emit('odoo-material-selected', value);
      },
    },
  });
</script>

<style scoped>
  .modal-title {
    background-color: #3b516b;
    color: white;
  }
</style>
