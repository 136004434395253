<template>
  <v-container fluid data-cy="container" style="padding-top: 0px">
    <v-card class="pa-3 wrapper" style="border: none" data-cy="main-card">
      <v-tabs v-model="tab" style="border-bottom: 2px solid" data-cy="tabs">
        <v-tab :key="0" :active="activeTab === 0" @click="activeTab = 0" data-cy="tab-overview">Components</v-tab>
        <v-tab :key="1" :active="activeTab === 1" @click="activeTab = 1" data-cy="tab-bom">View BoM</v-tab>
        <v-spacer></v-spacer>
        <v-text-field
          class="mr-3"
          :style="{ maxWidth: '450px' }"
          v-model="generation_item"
          density="compact"
          label="PN"
          variant="underlined"
          hide-details
          single-line
          data-cy="search-field"
          @keydown.stop
          :loading="loadingData"
        ></v-text-field>
        <v-btn class="btn-orange" :disabled="generation_item.length === 0" @click="generate" data-cy="new-button">Generate</v-btn>
      </v-tabs>

      <div v-if="tab === 0" data-cy="tab-content-overview">
        <v-row v-if="convertedItems.length && product_info.length">
          <v-col cols="12" md="12" class="info">
            <div class="item-info">
              <h2>
                Product Info:
                <span class="product-description">{{ product_info || 'No Data' }}</span>
              </h2>
            </div>
          </v-col>
          <v-col cols="12" md="4" class="tree">
            <div class="structure-container">
              <n-space vertical>
                <n-tree :show-line="true" :default-expanded-keys="defaultExpandedKeys" :data="convertedItems" :node-props="nodeProps" />
              </n-space>
            </div>
          </v-col>

          <v-col cols="12" md="8" class="details">
            <div class="details-container">
              <div class="text-details" data-cy="details-content">
                <div>
                  <v-row>
                    <!-- LOCAL -->
                    <v-col cols="12" md="12" style="padding-bottom: 0px" v-if="item_details?.attributeValues?.length">
                      <v-card-title>
                        <v-icon @click="isExpandedLocal = !isExpandedLocal" class="mr-2" cursor="pointer">
                          {{ isExpandedLocal ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                        </v-icon>
                        Local Attributes
                      </v-card-title>
                      <v-expand-transition>
                        <v-card-text v-if="isExpandedLocal">
                          <v-table density="compact">
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th style="min-width: 130px">Name</th>
                                  <th>Value</th>
                                  <th>UoM</th>
                                  <th>Source</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(attr, index) in item_details.attributeValues" :key="index">
                                  <td>{{ attr.description }}</td>

                                  <td v-if="attr.attributeId === 'error'">
                                    <span class="error-attr">⚠️ {{ attr.value }}</span>
                                  </td>
                                  <td v-else>{{ attr.value }}</td>
                                  <td>{{ attr.uom }}</td>
                                  <td>
                                    <span v-if="attr?.inheritedFrom?.name" class="inheritedFrom">{{ attr?.inheritedFrom?.name }}</span>
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-table>
                        </v-card-text>
                      </v-expand-transition>
                    </v-col>
                    <!-- MATERIAL -->
                    <v-col cols="12" md="12" style="padding-bottom: 0px; padding-top: 0px" v-if="item_details?.materials?.length">
                      <v-card-title>
                        <v-icon @click="isExpandedMaterial = !isExpandedMaterial" class="mr-2" cursor="pointer">
                          {{ isExpandedMaterial ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                        </v-icon>
                        Materials
                        <span class="material-title">[{{ item_details?.materials[0]?.odooMaterial?.pn }}]</span>
                      </v-card-title>
                      <v-expand-transition>
                        <v-card-text v-if="isExpandedMaterial">
                          <v-table density="compact">
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th style="min-width: 130px">Name</th>
                                  <th>Value</th>
                                  <th>UoM</th>
                                  <th>Source</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr v-for="(attr, index) in item_details?.materials[0].attributes" :key="index">
                                  <td>{{ attr.description }}</td>

                                  <td v-if="attr.attributeId === 'error'">
                                    <span class="error-attr">⚠️ {{ attr.value }}</span>
                                  </td>
                                  <td v-else>{{ attr.value }}</td>
                                  <td>{{ attr.uom }}</td>
                                  <td>
                                    <span v-if="attr?.inheritedFrom?.name" class="inheritedFrom">{{ attr?.inheritedFrom?.name }}</span>
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-table>
                        </v-card-text>
                      </v-expand-transition>
                    </v-col>
                    <!-- PARENT -->
                    <v-col cols="12" md="12" style="padding-top: 0px" v-if="item_details?.availableAttributeValues">
                      <v-card-title>
                        <v-icon @click="isExpandedParent = !isExpandedParent" class="mr-2" cursor="pointer">
                          {{ isExpandedParent ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                        </v-icon>
                        Parent Attributes
                      </v-card-title>
                      <v-expand-transition>
                        <v-card-text v-if="isExpandedParent">
                          <v-table density="compact">
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th style="min-width: 130px">Name</th>
                                  <th>Value</th>
                                  <th>UoM</th>
                                  <th>Source</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(attr, index) in item_details.availableAttributeValues" :key="index">
                                  <td>{{ attr.description }}</td>

                                  <td v-if="attr.attributeId === 'error'">
                                    <span class="error-attr">⚠️ {{ attr.value }}</span>
                                  </td>
                                  <td v-else>{{ attr.value }}</td>
                                  <td>{{ attr.uom }}</td>
                                  <td>
                                    <span v-if="attr?.inheritedFrom?.name" class="inheritedFrom">
                                      {{ attr?.inheritedFrom?.name }}
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-table>
                        </v-card-text>
                      </v-expand-transition>
                    </v-col>
                    <v-col cols="12" md="12" v-else>
                      <div class="text-center no-data" data-cy="details-content">
                        <h2 data-cy="details-title">No data</h2>
                        <p data-cy="details-description">This element does not have any attributes.</p>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>

        <div v-else data-cy="tab-content-details">
          <div class="text-center no-data" data-cy="details-content" v-if="!loadingData">
            <h2 data-cy="details-title">No data</h2>
            <p data-cy="details-description">Please add PN and press generate</p>
          </div>
          <div v-else>
            <v-row>
              <v-col cols="12" md="12">
                <v-skeleton-loader type="heading" :loading="loadingData" rows="5" row-height="30" class="mt-5" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <v-skeleton-loader type="article" :loading="loadingData" rows="5" row-height="30" class="mt-5" />
              </v-col>
              <v-col cols="12" md="8">
                <v-skeleton-loader type="heading, table-row-divider@6" :loading="loadingData" rows="5" row-height="30" class="mt-5" />
              </v-col>
            </v-row>
          </div>
        </div>
      </div>

      <div v-else-if="tab === 1" data-cy="tab-content-bom">
        <v-row>
          <v-col cols="12" md="12">
            <div v-if="viewBom">
              <v-table density="compact">
                <thead>
                  <tr>
                    <th v-for="(header, index) in viewBom[0]" :key="index">{{ header }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, rowIndex) in viewBom.slice(1)" :key="rowIndex">
                    <td v-for="(cell, cellIndex) in row" :key="cellIndex">{{ cell }}</td>
                  </tr>
                </tbody>
              </v-table>
            </div>

            <div v-else class="text-center no-data" data-cy="details-content">
              <div v-if="!loadingData">
                <h2 data-cy="details-title">No BoM data</h2>
                <p data-cy="details-description">Please add PN and press generate</p>
              </div>
              <v-skeleton-loader v-else type="heading, table-row-divider@6" :loading="loadingData" rows="5" row-height="30" class="mt-5" />
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-container>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import apiClient from '@/services/axios';

  export default defineComponent({
    name: 'Generate',
    data() {
      return {
        tab: 0,
        generation_item: '',
        selected_item: null as any,
        item_details: null as any,
        item_pn: null as any,
        product_info: null as any,
        items: [] as any,
        isExpandedParent: true,
        isExpandedLocal: true,
        isExpandedMaterial: true,
        activeTab: 0,
        viewBom: null as any,
        convertedItems: [] as any[],
        defaultExpandedKeys: [],
        loadingData: false,
      };
    },
    watch: {
      selected_item(val: number) {
        this.update_details(val);
      },
    },
    methods: {
      update_details(val: any) {
        this.item_details = null;

        const findItemById = (items: any[], id: number): any => {
          for (const item of items) {
            if (item.id === id) {
              return item;
            }
            if (item.children) {
              const found = findItemById(item.children, id);
              if (found) return found;
            }
          }
          return null;
        };

        this.item_details = findItemById(this.items, val.key);
      },
      generate() {
        this.clear();
        this.item_pn = this.generation_item;
        this.getTree(this.item_pn);
      },
      convertToNaiveTree(vuetifyItems: any[]): any[] {
        return vuetifyItems.map((item) => {
          const node: any = {
            key: item.id,
            label: item.name,
          };

          // Only include children if there are any
          if (item.children && item.children.length > 0) {
            node.children = this.convertToNaiveTree(item.children);
          }

          return node;
        });
      },
      async getTree(item: any) {
        this.loadingData = true;
        try {
          const response = await apiClient.get(`/generator/${item}`);
          if (response && response.data) {
            const data = response.data.tree;
            this.items = [data];
            this.product_info = response.data?.description;
            this.viewBom = response.data?.bom;
            this.convertedItems = this.convertToNaiveTree(this.items);
            // console.log(this.convertedItems);
          }
          this.loadingData = false;
        } catch (error: any) {
          this.loadingData = false;
          const errorMessage = error.response?.data?.message || error.message || 'An unknown error occurred';
          this.$log.showError(`Error: ${errorMessage}`);
        }
      },

      nodeProps({ option }: { option: any }) {
        return {
          onClick: () => {
            this.handleTitleClick(option);
          },
          onContextmenu: (event: MouseEvent) => {
            //event.preventDefault();
          },
          class: {
            'selected-node': this.selected_item && this.selected_item.key === option.key,
            'bold-node': this.selected_item && this.selected_item.key === option.key,
          },
        };
      },
      handleTitleClick(item: any) {
        this.selected_item = item;
        this.item_details = item;
      },
      clear() {
        this.items = [];
        this.item_details = null;
        this.product_info = null;
        this.convertedItems = [];
      },
    },
  });
</script>

<style scoped>
  .wrapper {
    min-height: calc(100vh - 120px);
  }
  .item-info {
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 5px;
  }
  .info {
    padding-bottom: 0px;
  }
  .tree {
    border-right: 1px solid #e0e0e0;
    padding-top: 16px !important;
  }
  .details {
    border-left: 1px solid #e0e0e0;
  }
  .no-data {
    padding-top: 50px;
  }
  .product-description {
    font-weight: 400;
    font-size: 1rem;
  }
  .custom-title {
    cursor: pointer;
    font-weight: bold;
  }
  .inheritedFrom {
    color: white;
    background-color: cadetblue;
    padding: 2px 10px;
    border-radius: 50px;
  }
  .error-attr {
    color: red;
  }
  .material-title {
    font-size: 0.8rem;
    font-weight: 400;
  }
  :deep(.selected-node > div) {
    font-weight: bold;
  }
  :deep(.n-tree .n-tree-node-indent.n-tree-node-indent--show-line::before) {
    border-left: 1px solid rgb(160, 160, 160);
  }
</style>
