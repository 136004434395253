<!-- src/views/materials/Materials.vue -->
<template>
  <v-container fluid data-cy="container" style="padding-top: 0px">
    <v-card class="pa-3" style="border: none" data-cy="main-card">
      <v-tabs v-model="tab" style="border-bottom: 2px solid" data-cy="tabs">
        <v-tab :key="0" data-cy="tab-overview" active>Overview</v-tab>
        <v-spacer></v-spacer>
        <v-select
          class="mr-3 filter-select"
          max-width="200"
          v-model="tableFilter"
          :items="tableFilterData"
          variant="underlined"
          data-cy="form-value-mode"
          prepend-inner-icon="mdi-filter"
        ></v-select>
        <v-text-field
          class="mr-3"
          max-width="200"
          v-model="search"
          density="compact"
          label="Search"
          prepend-inner-icon="mdi-magnify"
          variant="underlined"
          hide-details
          single-line
          data-cy="search-field"
        ></v-text-field>
        <v-btn class="btn-orange" @click="openModal('new')" data-cy="new-button">New</v-btn>
      </v-tabs>

      <div v-if="tab === 0" data-cy="tab-content-overview">
        <v-data-table
          :headers="headers"
          :items="filteredItems"
          class="custom-table"
          data-cy="data-table"
          loading-text="Loading... Please wait"
          :loading="loadingData"
          v-model:items-per-page="itemsPerPage"
          :items-per-page-options="itemsPerPageOptions"
        >
          <template v-slot:item.pn="{ item }">
            {{ item.odooMaterial.pn }}
          </template>

          <template v-slot:item.componentType="{ item }">
            {{ getComponentTypeName(item.componentTypeId) }}
          </template>

          <template v-slot:item.description="{ item }">
            <n-ellipsis style="max-width: 500px">{{ item.odooMaterial.description.replace(/\[.*?\]\s*/, '') }}</n-ellipsis>
          </template>

          <template v-slot:item.actions="{ item }">
            <div class="controls-wrapper">
              <span @click="openModal('edit', item)"><i class="fa-duotone fa-solid fa-pen-to-square"></i></span>
              <span @click="confirmDelete(item)"><i class="fa-duotone fa-solid fa-trash"></i></span>
            </div>
          </template>
        </v-data-table>
      </div>

      <MaterialsModal
        :modal="modal"
        :modalTitle="modalTitle"
        :odooMaterialsOptions="odooMaterialsOptions"
        :selected="selected"
        :productFamilyOptions="productFamilyOptions"
        :filteredComponents="filteredComponents"
        :genUnitOptions="genUnitOptions"
        :loadingMaterials="loadingMaterials"
        :searchOdooMaterials="searchOdooMaterials"
        :loadingDataFromPM="loadingDataFromPM"
        @close="closeModal"
        @save="saveMaterial"
        @search-odoo-materials="onSearchOdooMaterials"
        @odoo-material-selected="onOdooMaterialSelected"
      />

      <Dialogs
        :cancelDialog="cancelDialog"
        :deleteDialog="deleteDialog"
        @closeCancelDialog="closeCancelDialog"
        @confirmCancel="confirmCancel"
        @closeDeleteDialog="closeDeleteDialog"
        @confirmDeleteItem="confirmDeleteItem"
      />
    </v-card>
  </v-container>
</template>

<script lang="ts">
  import { defineComponent, ref, onMounted } from 'vue';
  import { useAttributesStore } from '@/store/attributesStore';
  import { useComponentsTypeStore } from '@/store/componentsTypesStore';
  import { Unit, UnitCategory } from '@/types/attributes';
  import { ProductFamily, Attribute, OdooMaterial, Material, uom } from '@/types/materials';
  import { useUserPreferencesStore } from '@/store/userPreferencesStore';
  import Dialogs from '@/components/Dialogs.vue';
  import MaterialsModal from '@/views/materials/MaterialsModal.vue';
  import materialsService from '@/services/materialsService';
  import attributeService from '@/services/attributeService';
  import etcService from '@/services/etcService';
  import componentService from '@/services/componentService';

  export default defineComponent({
    name: 'Materials',
    components: {
      Dialogs,
      MaterialsModal,
    },
    data() {
      return {
        tab: 0,
        search: '',
        modal: false,
        deleteDialog: false,
        cancelDialog: false,
        modalTitle: '',
        loadingData: false,
        headers: [
          { title: 'Material P/N', value: 'pn', sortable: true, width: '20%' },
          { title: 'Description', value: 'description', sortable: true, width: '50%' },
          { title: 'Logic Module ', value: 'componentType', sortable: true, width: '20%' },
          { title: 'Actions', value: 'actions', sortable: false, width: '10%' },
        ],
        materialsData: [] as Material[],
        attributesData: [] as Attribute[],
        componentsData: [] as any,
        nameError: '',
        itemToDelete: null as Material | null,
        odooMaterialsOptions: [] as OdooMaterial[],
        odooMaterialFromProductMaster: [] as OdooMaterial[],
        productFamilyOptions: [] as ProductFamily[],
        unitOptions: [] as UnitCategory[],
        uomData: [] as Unit[],

        selected: {
          odooMaterial: null as OdooMaterial | null,
          productFamilyCode: null as ProductFamily | null,
          componentType: null as any,
          attributes: [] as Attribute[],
          uomId: null as uom | null,
        },
        find_materials: '',
        loadingMaterials: false,
        searchOdooMaterials: '',
        materialItem: null as Material | null,

        tableFilter: 'All',
        tableFilterData: ['All'],
        loadingDataFromPM: false,
        itemsPerPage: 10,
        itemsPerPageOptions: [10, 25, 50, 100, -1],
      };
    },
    mounted() {
      this.getComponentsTypeList();
      this.getMaterialsList();
      this.getProductFamilyCode();
      this.getUnits();
      this.getAttributesList();
      this.restoreUserPreferences();
    },

    computed: {
      filteredItems() {
        let items = this.materialsData.slice();

        if (this.tableFilter !== 'All') {
          items = items.filter((item) => {
            const componentTypeName = this.getComponentTypeName(item.componentTypeId);
            return componentTypeName === this.tableFilter;
          });
        }

        if (this.search) {
          const searchTerm = this.search.toLowerCase();
          items = items.filter((item: Material) => {
            const pn = item.odooMaterial?.pn?.toLowerCase() || '';
            const description = item.odooMaterial?.description?.toLowerCase() || '';
            const productFamilyCode = item.productFamily?.code?.toLowerCase() || '';
            const productFamilyName = item.productFamily?.name?.toLowerCase() || '';
            const componentTypeName = this.getComponentTypeName(item.componentTypeId)?.toLowerCase() || '';
            const uomName = item.uom?.name?.toLowerCase() || '';
            const uomAlias = item.uom?.alias?.toLowerCase() || '';

            const searchableContent = [pn, description, productFamilyCode, productFamilyName, componentTypeName, uomName, uomAlias].join(' ');
            return searchableContent.includes(searchTerm);
          });
        }

        items = items.map((item) => ({
          ...item,
          pn: item.odooMaterial?.pn || '',
          description: item.odooMaterial?.description || '',
          componentType: this.getComponentTypeName(item.componentTypeId) || '',
        }));

        return items;
      },

      filteredComponents() {
        if (this.selected.productFamilyCode) {
          const selectedFamilyId = this.selected.productFamilyCode.id;
          const matchingComponents = this.componentsData.filter((component: any) => component.productFamily?.id === selectedFamilyId);
          const componentIds = matchingComponents.map((component: any) => component.id);
          const filteredComponents = this.componentsData.filter((component: any) => componentIds.includes(component.id));
          return filteredComponents;
        }

        return this.componentsData;
      },

      genUnitOptions() {
        const options: any[] = [];

        this.unitOptions.forEach((category: UnitCategory) => {
          if (category.name) {
            options.push({ props: { header: category.name } });
          }

          if (Array.isArray(category.value)) {
            category.value.forEach((unit: Unit) => {
              options.push({
                title: `${unit.name} (${unit.alias})`,
                value: unit,
                props: { value: unit },
              });
            });
          }

          options.push({ props: { divider: true } });
        });

        if (options.length > 0 && options[options.length - 1].props.divider) {
          options.pop();
        }

        return options;
      },
    },
    watch: {
      searchOdooMaterials(val) {
        if (val.length > 2) {
          this.onSearchOdooMaterials(val);
        }
      },
      'selected.componentType': {
        handler(newVal) {
          if (newVal) {
            this.updateAttributes(newVal);
          }
        },
        immediate: true,
      },
      'selected.productFamilyCode': {
        handler(newVal) {
          if (newVal) {
            // this.selected.componentType = null;
            this.updateComponentTypes();
          }
        },
        immediate: true,
      },
      tableFilter(newFilter) {
        this.saveUserPreferences({ tableFilter: newFilter });
      },
      itemsPerPage(newItemsPerPage) {
        this.saveUserPreferences({ itemsPerPage: newItemsPerPage });
      },
    },
    methods: {
      async getMaterialsList() {
        this.loadingData = true;
        try {
          const materials = await materialsService.getMaterialsList();
          this.materialsData = materials;
          this.updateTableFilterData();
        } catch (error) {
          this.$log.showError(`Failed to fetch materials: ${error}`);
        } finally {
          this.loadingData = false;
        }
      },
      async getAttributesList() {
        try {
          const attributesStore = useAttributesStore();
          const sortedAttributes = await attributeService.getAttributesList();
          this.attributesData = sortedAttributes;
          attributesStore.setAttributesData(sortedAttributes);
        } catch (error) {
          console.error('Failed to fetch attributes:', error);
        }
      },
      async getComponentsTypeList() {
        try {
          const componentsTypeStore = useComponentsTypeStore();
          const sortedComponentTypes = await componentService.getComponentsList();
          this.componentsData = sortedComponentTypes;
          componentsTypeStore.setComponentsTypesData(sortedComponentTypes);
        } catch (error) {
          console.error('Failed to fetch components:', error);
        }
      },
      async getProductFamilyCode() {
        try {
          const response = await etcService.getProductFamilyCode();

          this.productFamilyOptions = response.data
              .map((item: any) => {
                let label = `${item.code} - ${item.label}`;
                if (label.length > 60) {
                  label = label.substring(0, 57) + '...';
                }
                return {
                  id: item.id,
                  code: item.code,
                  label,
                };
              })
              .sort((a: any, b: any) => a.code.localeCompare(b.code));
          
        } catch (error: any) {
          console.error('Failed to fetch product family code:', error);
        }
      },
      async getUnits() {
        try {
          const units = await etcService.getUnits();
          this.unitOptions = units;
        } catch (error) {
          this.$log.showError(`Failed to fetch units: ${error}`);
        }
      },

      async getOdooMaterials(find_materials: string) {
        try {
          this.odooMaterialsOptions = await etcService.getOdooMaterials(find_materials);
        } catch (error) {
          this.$log.showError(`Failed to fetch Odoo materials: ${error}`);
        }
      },

      async getOdooMaterialFromProductMaster(find_material: string) {
        try {
          this.loadingDataFromPM = true;
          this.odooMaterialFromProductMaster = await etcService.getOdooMaterialFromProductMaster(find_material);
          this.populateAttributesValues();
          this.loadingDataFromPM = false;
        } catch (error) {
          this.loadingDataFromPM = false;
          this.$log.showError(`Failed to fetch Odoo materials: ${error}`);
        }
      },
      async createMaterial() {
        const saveData = this.prepareMaterialSaveData();
        try {
          await materialsService.createMaterial(saveData);
          this.getMaterialsList();
          this.closeModal();
        } catch (error) {
          this.$log.showError(`Failed to create material: ${error}`);
        }
      },
      async updateMaterial() {
        const saveData = this.prepareMaterialSaveData();
        try {
          await materialsService.updateMaterial(this.materialItem!.id, saveData);
          this.getMaterialsList();
          this.closeModal();
        } catch (error) {
          this.$log.showError(`Failed to update material: ${error}`);
        }
      },

      async deleteMaterial(id: string) {
        try {
          await materialsService.deleteMaterial(id);
          this.getMaterialsList();
        } catch (error) {
          this.$log.showError(`Failed to delete material: ${error}`);
        }
      },

      prepareMaterialSaveData() {
        return {
          componentTypeId: this.selected.componentType?.id || null,
          productFamily: {
            id: this.selected.productFamilyCode?.id || null,
            code: this.selected.productFamilyCode?.code || '',
            name: this.selected.productFamilyCode?.label || '',
          },
          attributes: this.selected.attributes.map((attr) => {
            return {
              id: attr.id, // Attribute ID
              isRequired: attr.isRequired || false,
              value: {
                value: this.extractFinalValue(attr.value),
              },
            };
          }),
          odooMaterial: {
            id: this.selected.odooMaterial?.id?.toString() || null,
            pn: this.selected.odooMaterial?.barcode?.toString() || '',
            description: this.selected.odooMaterial?.display_name || '',
          },
          uom: this.selected.uomId?.value?.id || null,
          quantityInStock: 0,
          pricePerUnit: 0,
        };
      },

      populateAttributesValues() {
        if (this.odooMaterialFromProductMaster && this.odooMaterialFromProductMaster.length > 0) {
          const material = this.odooMaterialFromProductMaster[0];

          if (material.components && Array.isArray(material.components)) {
            material.components.forEach((component: any) => {
              const matchingAttribute = this.selected.attributes.find((attr) => attr.pg_id === component.id);

              if (matchingAttribute) {
                const attributeData = this.attributesData.find((attr) => attr.id === matchingAttribute.id);

                if (attributeData && attributeData.values && Array.isArray(attributeData.values)) {
                  let matchingValue;

                  if (component.value.id === 'null' && component.value.label && component.value.label.startsWith('userInput')) {
                    matchingValue = { value: component.value.code };
                  } else {
                    matchingValue = attributeData.values.find((val) => val.pg_id === component.value.id);
                  }

                  if (matchingValue) {
                    matchingAttribute.value = {
                      ...matchingAttribute.value,
                      value: matchingValue.value,
                    };
                  }
                }
              }
            });
          }

          // console.log('Updated Attributes with Matched Values: ', this.selected.attributes);
        }
      },
      async onSearchOdooMaterials(searchOdooMaterials: string) {
        // console.log('searchOdooMaterials:', searchOdooMaterials);
        if (searchOdooMaterials.length >= 3) {
          this.loadingMaterials = true;
          await this.getOdooMaterials(searchOdooMaterials);
          this.loadingMaterials = false;
        }
      },

      getComponentTypeName(componentTypeId: string): string {
        const componentType = this.componentsData.find((component: any) => component.id === componentTypeId);
        return componentType ? componentType.name : 'Unknown';
      },

      updateAttributes(componentType: any) {
        this.selected.attributes = [];

        if (componentType && Array.isArray(componentType.attributes)) {
          componentType.attributes.forEach((attributeObj: any) => {
            const attributeId = attributeObj.attributeId;
            const material = this.materialItem;

            let foundAttribute;

            if (material && Array.isArray(material.attributes)) {
              foundAttribute = material.attributes.find((attr) => attr.id === attributeId);
            }

            const findAttribute = this.attributesData.find((attribute) => attribute.id === attributeId);

            if (findAttribute) {
              const newAttribute = foundAttribute
                ? {
                    ...findAttribute,
                    ...foundAttribute,
                    value: {
                      ...(foundAttribute?.value || findAttribute?.value || { value: '' }),
                    },
                  }
                : {
                    ...findAttribute,
                    value: findAttribute?.value || { value: '' },
                  };

              this.selected.attributes.push(newAttribute);
              // console.log('New Attribute:', newAttribute);
            }
          });
        }
      },
      updateComponentTypes() {
        this.selected.componentType = this.filteredComponents[0];
      },
      updateTableFilterData() {
        const componentTypeIds = new Set(this.materialsData.map((item: Material) => item.componentTypeId));
        const componentTypeNames = Array.from(componentTypeIds)
          .map((id) => this.getComponentTypeName(id))
          .filter((name) => name !== 'Unknown')
          .sort((a, b) => a.localeCompare(b));

        this.tableFilterData = ['All', ...componentTypeNames];
      },
      openModal(action: 'new' | 'edit', item: Material | null = null) {
        this.modalTitle = action === 'new' ? 'Create Material' : 'Edit Material';
        if (item) {
          this.materialItem = { ...item };
          this.selected.odooMaterial = {
            id: item.odooMaterial.id,
            pn: item.odooMaterial.pn,
            barcode: item.odooMaterial.pn,
            display_name: item.odooMaterial.description,
            description: item.odooMaterial.description,
          };
          this.selected.productFamilyCode = {
            id: item.productFamily.id,
            code: item.productFamily.code,
            label: item.productFamily.name,
            segment: item.productFamily.segment,
            name: item.productFamily.name,
            type: item.productFamily.type,
          };
          this.selected.componentType = this.componentsData.find((c: any) => c.id === item.componentTypeId);
          this.selected.uomId = this.loadUomDataById(item.uom);
          this.updateAttributes(this.selected.componentType);
        } else {
          this.resetMaterialData();
        }
        this.modal = true;
      },

      loadUomDataById(id: any) {
        const category = this.unitOptions.find((unit) => unit.value?.some((item) => item.id === id));

        if (category) {
          const item = category.value?.find((v) => v.id === id);

          if (item) {
            return {
              title: `${item.name} (${item.alias})`,
              value: item,
              props: {
                value: item,
              },
            };
          }
        }
        return null;
      },

      resetMaterialData() {
        this.materialItem = null;
        this.selected = {
          odooMaterial: null,
          productFamilyCode: null,
          componentType: null,
          attributes: [],
          uomId: null,
        };
      },

      saveMaterial(title: string) {
        if (title === 'Create Material') {
          this.createMaterial();
        } else {
          this.updateMaterial();
        }
      },

      extractFinalValue(value: any): any {
        if (value && typeof value === 'object' && 'value' in value) {
          return this.extractFinalValue(value.value);
        }
        return value;
      },
      onOdooMaterialSelected(odooMaterial: OdooMaterial) {
        if (!odooMaterial || !odooMaterial.display_name) return;

        const extractedCodeMatch = odooMaterial.display_name.match(/\[(.*?)\]/);
        let extractedCode = '';

        if (extractedCodeMatch && extractedCodeMatch[1]) {
          extractedCode = extractedCodeMatch[1].split('-')[0].trim();
        }

        const matchingProductFamily = this.productFamilyOptions.find((family) => family.code === extractedCode);

        if (matchingProductFamily) {
          this.selected.productFamilyCode = matchingProductFamily;
        } else {
          this.$log.showWarning(`No matching product family found for code`);
        }

        if (odooMaterial.uom_id && odooMaterial.uom_id.val) {
          const uomName = odooMaterial.uom_id.val;

          let matchingUom = null;

          for (const category of this.unitOptions) {
            matchingUom = category.value?.find((uom) => uom.name === uomName);
            if (matchingUom) break;
          }

          if (matchingUom) {
            this.selected.uomId = {
              title: `${matchingUom.name} (${matchingUom.alias})`,
              value: matchingUom,
              props: {
                value: matchingUom,
              },
            } as any;
          } else {
            this.$log.showWarning(`No matching UOM found for name: ${uomName}`);
            this.selected.uomId = null;
          }
          if (extractedCodeMatch) {
            this.getOdooMaterialFromProductMaster(extractedCodeMatch[1]);
          }
        } else {
          this.$log.showWarning(`No UOM found for the selected Odoo Raw Material`);
          this.selected.uomId = null;
        }
      },

      cleardata() {
        this.selected = {
          odooMaterial: null,
          productFamilyCode: null,
          componentType: null,
          attributes: [],
          uomId: null,
        };
      },

      truncateText(value: string, length: number): string {
        if (!value) return '';
        return value.length <= length ? value : `${value.substring(0, length)}...`;
      },
      confirmDelete(item: Material) {
        this.itemToDelete = item;
        this.deleteDialog = true;
      },
      closeDeleteDialog() {
        this.deleteDialog = false;
        this.itemToDelete = null;
      },
      confirmDeleteItem() {
        if (this.itemToDelete) {
          this.materialsData = this.materialsData.filter((i) => i.id !== this.itemToDelete!.id);
          this.deleteMaterial(this.itemToDelete!.id);
          this.closeDeleteDialog();
        }
      },

      confirmCancel() {
        this.closeModal();
        this.cancelDialog = false;
      },
      closeCancelDialog() {
        this.cancelDialog = false;
      },
      closeModal() {
        this.modal = false;
      },
      saveUserPreferences(preferences: { tableFilter?: string; itemsPerPage?: number }) {
        const userPreferencesStore = useUserPreferencesStore();
        if (preferences.tableFilter !== undefined) {
          userPreferencesStore.setMaterialsTableFilterStore(preferences.tableFilter);
        }
        if (preferences.itemsPerPage !== undefined) {
          userPreferencesStore.setMaterialsTableItemsPerPage(preferences.itemsPerPage);
        }
      },

      restoreUserPreferences() {
        const userPreferencesStore = useUserPreferencesStore();
        this.tableFilter = userPreferencesStore.pageTableFilters.materialsTableFilterStore;
        this.itemsPerPage = userPreferencesStore.itemsPerPage.materialsTableItemsPerPage;
      },
    },
  });
</script>

<style scoped>
  .v-data-table {
    margin-top: 20px;
  }

  .modal-title {
    background-color: #3b516b;
    color: white;
  }

  .custom-table .v-data-table__wrapper {
    overflow-x: auto;
  }

  .custom-table th,
  .custom-table td {
    max-width: 20%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }

  .uom-select-header {
    background-color: #f5f5f5;
    color: #000;
    font-weight: bold;
  }

  .controls-wrapper {
    display: flex;
    justify-content: space-around;
    font-size: 18px;
  }
  .controls-wrapper span {
    cursor: pointer;
  }
  .controls-wrapper span > i:hover {
    color: orange;
  }
  .controls-wrapper span > i.fa-pen-to-square:hover {
    color: rgb(64, 64, 194);
  }
  .controls-wrapper span > i.fa-trash:hover {
    color: red;
  }
  .filter-select {
    margin: -9px 0px 0px 0px;
  }
</style>
