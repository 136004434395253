<!-- src/components/Dialogs.vue -->
<template>
  <!-- Cancel Confirmation Dialog -->
  <v-dialog :model-value="cancelDialog" @update:model-value="onCloseCancelDialog" max-width="400px" data-cy="cancel-dialog">
    <v-card data-cy="cancel-dialog-card">
      <v-card-title class="headline" data-cy="cancel-dialog-title">Confirm Cancel</v-card-title>
      <v-card-text data-cy="cancel-dialog-text">All changes will be lost. Do you want to continue?</v-card-text>
      <v-card-actions data-cy="cancel-dialog-actions" class="btn-modal-bottom">
        <v-spacer></v-spacer>
        <v-btn class="btn-cancel" @click="onCloseCancelDialog" data-cy="cancel-no-button">No</v-btn>
        <v-btn class="btn-delete" @click="onConfirmCancel" data-cy="cancel-yes-button">Yes</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- Delete Confirmation Modal -->
  <v-dialog :model-value="deleteDialog" @update:model-value="onCloseDeleteDialog" max-width="400px" data-cy="delete-dialog">
    <v-card data-cy="delete-dialog-card">
      <v-card-title class="headline" data-cy="delete-dialog-title">Confirm Delete</v-card-title>
      <v-card-text data-cy="delete-dialog-text">
        Are you sure you want to
        <strong>delete</strong>
        this attribute?
        <br />
        This operation can’t be undone.
      </v-card-text>
      <v-card-actions data-cy="delete-dialog-actions" class="btn-modal-bottom">
        <v-spacer></v-spacer>
        <v-btn class="btn-cancel" @click="onCloseDeleteDialog" data-cy="delete-cancel-button">Cancel</v-btn>
        <v-btn class="btn-delete" @click="onConfirmDeleteItem" data-cy="delete-confirm-button">Delete</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  export default {
    props: {
      cancelDialog: {
        type: Boolean,
        required: false,
        default: undefined,
      },
      deleteDialog: {
        type: Boolean,
        required: true,
        default: undefined,
      },
    },
    emits: ['closeCancelDialog', 'confirmCancel', 'closeDeleteDialog', 'confirmDeleteItem', 'update:cancelDialog', 'update:deleteDialog'],
    methods: {
      onCloseCancelDialog() {
        this.$emit('update:cancelDialog', false);
        this.$emit('closeCancelDialog');
      },
      onConfirmCancel() {
        this.$emit('confirmCancel');
      },
      onCloseDeleteDialog() {
        this.$emit('update:deleteDialog', false);
        this.$emit('closeDeleteDialog');
      },
      onConfirmDeleteItem() {
        this.$emit('confirmDeleteItem');
      },
    },
  };
</script>

<style scoped>
  .btn-modal-bottom {
    display: flex;
    justify-content: space-between;
  }
</style>
